import {
  AssistantMessage,
  Product,
  ThumbsState,
  UserChatAction,
  UserProductCardAction,
  UserProductCardMessage,
} from 'charlie-workflows/types';
import { inject, injectable } from 'inversify';
import { Observable, map } from 'rxjs';
import * as httpTypes from '../http';
import { UserChatActionMessage } from './../../../../charlie-workflows/types/messages';
import { ICreateChatArgs, IMessagesApiService } from './messages-api.types';
import { SendMessageMetadata } from '../messages/messages.types';

@injectable()
export class MessagesApiService implements IMessagesApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public sendMessage = (
    chatId: string,
    details: SendMessageMetadata,
  ): Observable<string> => {
    const formData = new FormData();

    formData.append('sessionId', chatId);
    formData.append(
      'message',
      JSON.stringify({
        type: 'userMessage',
        payload: {
          message: details.content,
          ...(details.generativeButtons
            ? { generativeButtons: details.generativeButtons }
            : {}),
        },
      }),
    );

    if (details.image) {
      formData.append('file', details.image);
    }

    return this.httpService
      .makeRequest<{ id: string }>('/api/chat', {
        method: 'POST',
        headers: {},
        body: formData,
      })
      .pipe(map((item) => item.id));
  };

  public sendAudioMessage = (chatId: string, file: Blob): Observable<void> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sessionId', chatId);

    return this.httpService.makeRequest('/api/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    });
  };

  public sendMessageFeedback(
    message: AssistantMessage,
    state: ThumbsState,
  ): Observable<void> {
    return this.httpService.makeRequest('/api/message/feedback', {
      method: 'POST',
      body: JSON.stringify({
        message,
        state,
      }),
    });
  }

  public sendProductCardAction = (
    chatId: string,
    type: UserProductCardAction,
    productIds: string[],
    product?: Product,
  ): Observable<UserProductCardMessage> => {
    const data = { type, productIds, ...(product ? { product } : null) };

    return this.httpService
      .makeRequest<UserProductCardMessage>('/api/chat', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: chatId,
          message: {
            type: 'userProductCardMessage',
            payload: data,
          },
        }),
      })
      .pipe(map((item) => item));
  };

  public sendUserChatActionMessage = (
    chatId: string,
    type: UserChatAction,
  ): Observable<UserChatActionMessage> => {
    const data = { actionType: type };

    return this.httpService
      .makeRequest<UserChatActionMessage>('/api/chat', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: chatId,
          message: {
            type: 'userChatActionMessage',
            payload: data,
          },
        }),
      })
      .pipe(map((item) => item));
  };

  public createChat = ({
    topic,
    prompts,
    promptExperience,
    streamingProductCardCountExperience,
    recommendationsPromptEngineVersion,
    recipientId,
    randomPromptExperimentFeatureFlagVariant,
  }: ICreateChatArgs): Observable<{ id: string }> => {
    const queryObject: Record<string, string> = {};

    if (topic) {
      queryObject['topic'] = topic;
    }

    if (promptExperience) {
      queryObject['promptExperience'] = promptExperience;
    }

    if (streamingProductCardCountExperience) {
      queryObject['streamingProductCardCountExperience'] =
        streamingProductCardCountExperience;
    }

    if (recommendationsPromptEngineVersion) {
      queryObject['recommendationsPromptEngineVersion'] =
        recommendationsPromptEngineVersion;
    }

    if (randomPromptExperimentFeatureFlagVariant) {
      queryObject['randomPromptExperimentFeatureFlagVariant'] =
        randomPromptExperimentFeatureFlagVariant;
    }

    if (recipientId) {
      queryObject['recipientId'] = recipientId;
    }

    const queryString = '?' + new URLSearchParams(queryObject).toString();

    return this.httpService.makeRequest<{ id: string }>(
      `/api/message${queryString}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        ...(prompts ? { body: JSON.stringify({ prompts }) } : null),
      },
    );
  };

  public sendAuthInfo = (): Observable<void> => {
    return this.httpService.makeRequest<void>('/api/message', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  };
}
