import {
  PredefinedBusyMessages,
  Product,
  SystemEvents,
  UserChatAction,
  UserProductCardAction,
} from 'charlie-workflows/types';
import { inject, injectable } from 'inversify';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { logger } from '@/src/utils/common.utils';

import * as chatEventsTypes from '../chat-events/chat-events.types';
import * as messagesTypes from '../messages/messages.types';
// import * as toasterTypes from '../toaster/toaster.types';
import { IAnyMessage, IChatStorageService } from './chat-storage.types';

@injectable()
export class MemoryChatService implements IChatStorageService {
  private _typingIndicator$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  public typingIndicator$: Observable<string | null> =
    this._typingIndicator$.asObservable();

  public messages$: Observable<IAnyMessage[] | null> =
    this.messagesService.messages$;

  public _systemEvents$: BehaviorSubject<Array<SystemEvents> | null> =
    new BehaviorSubject<Array<SystemEvents> | null>(null);
  public systemEvents$: Observable<Array<SystemEvents> | null> =
    this._systemEvents$.asObservable();

  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  public _isChatInitialized$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isChatInitialized$: Observable<boolean> =
    this._isChatInitialized$.asObservable();

  public stream$ = this.chatEventsService.stream$.pipe(
    tap(({ chatId }) => {
      if (chatId) {
        this.messagesService.initialize();

        this.chatEventsService.subscribeToMessageEvents(this.messageListener);
      }
    }),
  );

  public streamParams$ = this.chatEventsService.streamParams$;

  constructor(
    @inject(chatEventsTypes.ChatEventsServiceContainerType)
    private chatEventsService: chatEventsTypes.IChatEventsService,
    @inject(messagesTypes.MessagesServiceContainerType)
    private messagesService: messagesTypes.IMessagesService,
  ) {}

  public initialize = (chatId: string): void => {
    this.streamParams$.next({
      chatId,
    });

    this._isChatInitialized$.next(false);
    this._isLoading$.next(false);
  };

  private messageListener: chatEventsTypes.MessagesListener = (message) => {
    logger('[SSE event received]: ', message);

    const { type } = message;
    switch (type) {
      case 'restoreMessages':
        this.messagesService.handleRestoreMessage(message);
        this._isChatInitialized$.next(true);
        break;
      case 'responseComplete':
        this._typingIndicator$.next(null);
        this._isLoading$.next(false);
        break;
      case 'busyMessage':
        this._typingIndicator$.next(message.payload.message);
        if (message.payload.showLoading) {
          this._isLoading$.next(true);
        }
        break;
      case 'tipPopup':
      case 'chatMetadata':
      case 'actionPopup':
        this._systemEvents$.next(
          (this._systemEvents$.getValue() || [])
            .filter((item) => item.type !== message.type)
            .concat([message]),
        );
        break;
      // {deprecated}
      // case 'profileMetadataAppend':
      //   const currentSystemEvents = this._systemEvents$.getValue();
      //   if (currentSystemEvents) {
      //     const currentProfileMetadata: ProfileMetadata =
      //       currentSystemEvents.find(
      //         (systemMessage) => systemMessage.type === 'profileMetadata',
      //       ) as ProfileMetadata;

      //     if (currentProfileMetadata) {
      //       const currentPayload = currentProfileMetadata.payload;
      //       const newPayload = Object.assign(
      //         {},
      //         currentProfileMetadata.payload,
      //       );

      //       const newProfileMetadata = {
      //         type: currentProfileMetadata.type,
      //         payload: {
      //           ...newPayload,
      //           ...(newPayload.title
      //             ? { title: (currentPayload.title || '') + newPayload.title }
      //             : null),
      //           ...(newPayload.bio
      //             ? { bio: (currentPayload.bio || '') + newPayload.bio }
      //             : null),
      //           ...(newPayload.progressDescription
      //             ? {
      //                 progressDescription:
      //                   (currentPayload.progressDescription || '') +
      //                   newPayload.progressDescription,
      //               }
      //             : null),
      //         },
      //       };

      //       this._systemEvents$.next(
      //         currentSystemEvents
      //           .filter((event) => event.type !== 'profileMetadata')
      //           .concat([newProfileMetadata]),
      //       );
      //     }
      //   }
      //   break;
    }

    if (type !== 'restoreMessages' && type !== 'chatMetadata') {
      this.messagesService.handleAppearedMessage(message);
    }
  };

  public removeSystemEvent = (event: SystemEvents) => {
    this._systemEvents$.next(
      (this._systemEvents$.getValue() || []).filter(
        (item) =>
          !(
            item.type === event.type &&
            item.payload.messageId === event.payload.messageId
          ),
      ),
    );
  };

  public destroy = (): void => {
    this.chatEventsService.unsubscribe(this.messageListener);
    this.chatEventsService.close();

    this.messagesService.destroy();

    this._typingIndicator$.next(null);
    this._isLoading$.next(false);
  };

  public sendMessage = (
    chatId: string,
    details: messagesTypes.SendMessageMetadata,
  ): void => {
    this._typingIndicator$.next(PredefinedBusyMessages.ProcessingRequest);

    this.messagesService.sendMessage(chatId, details);
    // if (typeof details.content === 'string') {
    //   this.messagesService.sendMessage(content, chatId, generativeButtons);
    // } else {
    //   this.messagesService.sendAudioMessage(content, chatId);
    // }
  };

  public sendUserChatActionMessage = (
    type: UserChatAction,
    chatId: string,
  ): void => {
    if (['show-more-step-suggestions'].includes(type)) {
      this._typingIndicator$.next(PredefinedBusyMessages.ProcessingRequest);
    }

    this.messagesService.sendUserChatActionMessage(type, chatId);
  };

  public sendProductMessage = (
    type: UserProductCardAction,
    chatId: string,
    productId: string[],
    product?: Product,
  ): void => {
    if (['show-else', 'show-more'].includes(type)) {
      this._typingIndicator$.next(PredefinedBusyMessages.ProcessingRequest);
    }

    this.messagesService.sendProductMessage(type, chatId, productId, product);
  };

  public getMessagesBySessionId = (
    id: string,
  ): Observable<IAnyMessage[] | null> => {
    return this.messagesService.getMessagesBySessionId(id);
  };
}
