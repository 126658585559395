import { injectable } from 'inversify';
import { toast, ToastOptions } from 'react-toastify';
import { IToasterService } from './toaster.types';
import 'reflect-metadata';

export const TOAST_CONFIG: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
  icon: undefined,
};

@injectable()
export class ToasterService implements IToasterService {
  success = (content: string): void => {
    toast.success(content, TOAST_CONFIG);
  };

  failed = (content: string): void => {
    toast.error(content, TOAST_CONFIG);
  };

  info = (content: string): void => {
    toast.info(content, TOAST_CONFIG);
  };
}
