import { IThumbsStatePayload } from '@/pages/api/products/feedback';
import { Observable } from 'rxjs';

export const ProductApiServiceContainerType = Symbol.for('ProductApiService');

export interface IProductApiService {
  setThumbsState({
    state,
    productId,
    sessionId,
  }: IThumbsStatePayload): Observable<string>;
  getDislikedProducts(sessionId: string): Observable<string[]>;
}
