export const FeatureFlagsServiceContainerType = Symbol.for(
  'FeatureFlagsService',
);

export enum SupportedFlags {
  ImageUpload = 'image-upload',
  SavedRecipients = 'saved-recipients',
}

export interface IFeatureFlagsService {
  getFlagValueForFeature(feature: SupportedFlags | string): boolean;
}
