import { AssistantMessage, ThumbsState } from 'charlie-workflows/types';
import { Observable } from 'rxjs';

export const FeedbackServiceContainerType = Symbol.for('FeedbackService');

export interface IFeedbackService {
  feedbacks$: Observable<Record<string, ThumbsState> | null>;
  dislikedProducts$: Observable<string[] | null>;
  sendFeedback(message: AssistantMessage, state: ThumbsState): void;

  initialize(chatId: string): void;
  destroy(): void;
}
