import { inject, injectable } from 'inversify';
import { IConversationsApiService } from './conversations-api.types';
import * as httpTypes from '../http';
import { Observable, from, map, switchMap } from 'rxjs';
import {
  PromptEditType,
  SessionPromptMetadata,
} from 'charlie-workflows/contracts/prompts';

@injectable()
export class ConversationsApiService implements IConversationsApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public branchConversation = (sharedId: string): Observable<string> => {
    return from(
      this.httpService.makeRequest<{ id: string }>('/api/branch', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sharedId,
        }),
      }),
    ).pipe(map((response) => response.id));
  };

  public getUserPrompts = (): Observable<
    Record<PromptEditType, string | undefined>
  > => {
    return from(
      this.httpService.makeRequest<{
        prompts: Record<PromptEditType, string | undefined>;
      }>(`/api/prompt-edit`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).pipe(map((response) => response.prompts));
  };

  public resetToDefaultPrompts = (): Observable<
    Record<PromptEditType, string | undefined>
  > => {
    return from(
      this.httpService.makeRequest<{
        prompts: Record<PromptEditType, string | undefined>;
      }>(`/api/prompt-edit/reset-to-default`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).pipe(map((response) => response.prompts));
  };

  public saveConversationPrompt = (
    promptMetadata: SessionPromptMetadata,
  ): Observable<Record<PromptEditType, string | undefined>> => {
    return from(
      this.httpService.makeRequest<Record<PromptEditType, string | undefined>>(
        `/api/prompt-edit`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(promptMetadata),
        },
      ),
    );
  };
}
