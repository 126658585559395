import { injectable } from 'inversify';
import { Observable, from, switchMap } from 'rxjs';

import { IAccountApiService } from './account-api.types';

@injectable()
export class AccountApiService implements IAccountApiService {
  public deleteAccount(): Observable<void> {
    return from(fetch('/api/account', { method: 'DELETE' })).pipe(
      switchMap((result) => result.json()),
    );
  }
}
