import { SessionEntity } from 'charlie-workflows/contracts/sessions';
import { Observable } from 'rxjs';

export const SessionsApiServiceContainerType = Symbol.for('SessionsApiService');

export interface ISessionsApiService {
  retrieveSessions(): Observable<SessionEntity[]>;
  updateSession(
    sessionId: string,
    sessionName: string,
  ): Observable<{ session: SessionEntity }>;
  deleteSession(sessionId: string): Observable<void>;
}
