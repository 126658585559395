import { inject, injectable } from 'inversify';
import { IAnalyticsApiService } from './analytics-api.types';
import * as httpServiceTypes from '../http/http.types';

@injectable()
export class AnalyticsApiService implements IAnalyticsApiService {
  constructor(
    @inject(httpServiceTypes.HttpServiceContainerType)
    private httpService: httpServiceTypes.IHttpService,
  ) {}

  public trackEvent = (name: string, data: unknown): void => {
    this.httpService
      .makeRequest('/api/analytics/event', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'key-code': '70883935553640088071841071679424',
        },
        body: JSON.stringify({ eventName: name, data }),
      })
      .subscribe();
  };
}
