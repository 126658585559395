import { FunctionComponent } from 'react';
import { Observable } from 'rxjs';

export const ModalServiceContainerType = Symbol.for('ModalService');

export interface ModalHandler {
  onClose(): void;
  id: string;
}

export interface BaseModalProps {
  className?: string;
  bodyClassName?: string;
  backdropClassName?: string;
  contentClassName?: string;
  onClose?: () => void;
}

export interface Modal<T extends BaseModalProps> {
  id: string;
  component: FunctionComponent<T>;
  props: T;
}

export interface IModalService {
  modals$: Observable<Modal<BaseModalProps>[]>;

  openModal<T>(
    component: FunctionComponent<T & BaseModalProps>,
    props?: T,
  ): ModalHandler;
  closeModal(id: string): void;
}
