import { injectable } from 'inversify';
import { IFeatureFlagsService, SupportedFlags } from './feature-flags.types';

@injectable()
export class FeatureFlagsService implements IFeatureFlagsService {
  constructor() {}

  getFlagValueForFeature(feature: SupportedFlags | string): boolean {
    if (feature === SupportedFlags.ImageUpload) {
      return process.env.NEXT_PUBLIC_IMAGE_UPLOAD_FEATURE_FLAG === 'enabled';
    }

    if (feature === SupportedFlags.SavedRecipients) {
      return true;
      // return (
      //   process.env.NEXT_PUBLIC_SAVED_RECIPIENTS_FEATURE_FLAG === 'enabled'
      // );
    }

    return false;
  }
}
