import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { inject, injectable } from 'inversify';
import { Product } from 'charlie-workflows/types';
import * as wishlistsApiTypes from '../wishlists-api/wishlists-api.types';
import { IWishlistsService } from './wishlists.types';
import { IWishList } from 'charlie-workflows/workflows/users/wishlist';

@injectable()
export class WishlistsService implements IWishlistsService {
  private _wishlists$: BehaviorSubject<IWishList[] | null> =
    new BehaviorSubject<IWishList[] | null>(null);
  public wishlists$: Observable<IWishList[] | null> =
    this._wishlists$.asObservable();

  constructor(
    @inject(wishlistsApiTypes.WishlistsApiServiceContainerType)
    private wishlistsApiService: wishlistsApiTypes.IWishlistsApiService,
  ) {}

  public retrieve = (): void => {
    this.wishlistsApiService
      .getAll()
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe((data) => {
        this._wishlists$.next(data);
      });
  };

  public retrieveOneById = (wishlistId: string): void => {
    this.wishlistsApiService
      .getWishlistById(wishlistId)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe((data) => {
        const currentWishlistsData = this._wishlists$.getValue();
        this._wishlists$.next(
          (currentWishlistsData || [])
            .filter((item) => item.id !== data.id)
            .concat([data]),
        );
      });
  };

  public createNew = (wishlistName: string, recipientId?: string) => {
    return this.wishlistsApiService.postWishlist(wishlistName, recipientId);
  };

  public attachToWishlist = (wishlistId: string, product: Product) => {
    return this.wishlistsApiService.postProduct(wishlistId, [product]);
  };

  public saveItemForRecipient = (recipientId: string, product: Product) => {
    return this.wishlistsApiService.postProductForRecipient(recipientId, [
      product,
    ]);
  };

  public deleteWishlist = (wishlistId: string) => {
    return this.wishlistsApiService.deleteWishlist(wishlistId);
  };

  public removeFromWishlist = (wishlistId: string, productId: string) => {
    return this.wishlistsApiService.getAll().pipe(
      switchMap((data) => {
        const firstWishlist = data.find((item) => item.id === wishlistId);
        const filteredProduct = firstWishlist?.products.find(
          (item) => item.payload.productId === productId,
        );

        if (!filteredProduct) {
          throw new Error(
            "Can't find a product with provided ID: " + productId,
          );
        }

        return this.wishlistsApiService.deleteProduct(
          wishlistId,
          filteredProduct,
        );
      }),
    );
  };
}
