import { Observable } from 'rxjs';

export const StorageServiceContainerType = Symbol.for('StorageService');

export enum KnownStorageKeys {
  Sessions = 'Sessions',
  Messages = 'Messages',
  Feedbacks = 'Feedbacks',
  ForceRefreshVersion = '1',
  ForceRefreshKey = 'forceRefresh',
  UserEmailStored = 'UserEmailStored',
  Wishlists = 'Wishlists',

  SideNavigationCollapsedState = 'side-nav-state',

  ProductToSave = 'ProductToSave',
  RecipientToSave = 'RecipientToSave',
}

export interface IStorageService {
  store<T>(key: KnownStorageKeys, value: T): void;
  retrieve<T>(key: KnownStorageKeys): T | null;
  clean(key: string): void;
}
