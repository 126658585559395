import { inject, injectable } from 'inversify';
import { Observable, map } from 'rxjs';
import { SessionEntity } from 'charlie-workflows/contracts/sessions';

import { ISessionsApiService } from './sessions-api.types';
import * as httpTypes from '../http';

@injectable()
export class SessionsApiService implements ISessionsApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public updateSession(
    sessionId: string,
    sessionName: string,
  ): Observable<{ session: SessionEntity }> {
    return this.httpService.makeRequest<{ session: SessionEntity }>(
      `/api/sessions/${sessionId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: sessionName }),
      },
    );
  }

  public deleteSession(sessionId: string): Observable<void> {
    return this.httpService.makeRequest<void>(`/api/sessions/${sessionId}`, {
      method: 'DELETE',
    });
  }

  public retrieveSessions(): Observable<SessionEntity[]> {
    return this.httpService
      .makeRequest<{ sessions: SessionEntity[] }>(`/api/sessions`, {
        method: 'GET',
      })
      .pipe(map((value) => value.sessions));
  }
}
