import { inject, injectable } from 'inversify';
import { Recipient } from 'charlie-workflows/types';
import { Observable, map } from 'rxjs';
import { RecipientEntity } from 'charlie-workflows/contracts';

import * as httpTypes from '../http';
import { IRecipientsApiService } from './recipients-api.types';
import { ExtendedRecipientInformation } from '@/pages/api/recipients/[id]';

@injectable()
export class RecipientsApiService implements IRecipientsApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public getRecipients: () => Observable<ExtendedRecipientInformation[]> =
    () => {
      return this.httpService
        .makeRequest<{ recipients: ExtendedRecipientInformation[] }>(
          '/api/recipients',
          {},
        )
        .pipe(map((result) => result.recipients));
    };

  public getRecipientById: (
    recipientId: string,
  ) => Observable<ExtendedRecipientInformation> = (recipientId) => {
    return this.httpService
      .makeRequest<{ recipient: ExtendedRecipientInformation }>(
        `/api/recipients/${recipientId}`,
        {
          method: 'GET',
        },
      )
      .pipe(map((data) => data.recipient));
  };

  public save: (
    recipient: Recipient,
    sessionToAttach?: string,
  ) => Observable<RecipientEntity> = (recipient, sessionToAttach) => {
    const body: Record<string, unknown> = {
      recipient,
    };

    if (sessionToAttach) {
      body['sessionToAttach'] = sessionToAttach;
    }

    return this.httpService
      .makeRequest<{ recipient: RecipientEntity }>('/api/recipients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      .pipe(map((data) => data.recipient));
  };

  public update: (
    recipientId: string,
    metadata: Partial<Recipient>,
  ) => Observable<RecipientEntity> = (recipientId, metadata) => {
    return this.httpService.makeRequest(`/api/recipients/${recipientId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metadata),
    });
  };

  public delete: (recipientId: string) => Observable<void> = (recipientId) => {
    return this.httpService.makeRequest(`/api/recipients/${recipientId}`, {
      method: 'DELETE',
    });
  };
}
