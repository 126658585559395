import { inject, injectable } from 'inversify';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { AssistantMessage, ThumbsState } from 'charlie-workflows/types';
import * as toasterTypes from '../toaster/toaster.types';
import { IFeedbackService } from './feedback.types';
import * as productApiTypes from '../product-api/product-api.types';
import * as messagesApiTypes from '../messages-api/messages-api.types';
import * as storageTypes from '../storage/storage.types';

@injectable()
export class FeedbackService implements IFeedbackService {
  private _feedbacks$ = new BehaviorSubject<Record<string, ThumbsState> | null>(
    null,
  );
  public feedbacks$: Observable<Record<string, ThumbsState> | null> =
    this._feedbacks$.asObservable();

  private storeSubscription: Subscription | null = null;
  private _dislikedProducts$: BehaviorSubject<string[] | null> =
    new BehaviorSubject<string[] | null>(null);
  public dislikedProducts$: Observable<string[] | null> =
    this._dislikedProducts$.asObservable();

  constructor(
    @inject(storageTypes.StorageServiceContainerType)
    private storageService: storageTypes.IStorageService,
    @inject(toasterTypes.ToasterServiceContainerType)
    private toasterService: toasterTypes.IToasterService,
    @inject(productApiTypes.ProductApiServiceContainerType)
    private productApiService: productApiTypes.IProductApiService,
    @inject(messagesApiTypes.MessagesApiServiceContainerType)
    private messagesApiService: messagesApiTypes.IMessagesApiService,
  ) {}

  public initialize = (chatId: string): void => {
    // this.productApiService
    //   .getDislikedProducts(chatId)
    //   .subscribe((productIds) => {
    //     const feedbacksMap: Record<string, ThumbsState> = productIds.reduce(
    //       (acc, dislikedProductId) => {
    //         return {
    //           ...acc,
    //           [dislikedProductId]: 'thumbs-down',
    //         };
    //       },
    //       {},
    //     );

    //     this._feedbacks$.next(feedbacksMap);
    //   });

    const value = this.storageService.retrieve<
      Record<string, IFeedbackService>
    >(storageTypes.KnownStorageKeys.Feedbacks);

    if (value) {
      this._feedbacks$.next(value as any);
    } else {
      this._feedbacks$.next({});
    }

    this.storeSubscription = this._feedbacks$.subscribe((value) => {
      this.storageService.store(storageTypes.KnownStorageKeys.Feedbacks, value);
    });
  };

  public destroy = (): void => {
    this.storeSubscription?.unsubscribe();
  };

  public sendFeedback = (
    message: AssistantMessage,
    state: ThumbsState,
  ): void => {
    this.messagesApiService.sendMessageFeedback(message, state).subscribe({
      next: () => {
        this.toasterService.success('Your feedback has been sent.');
        this.addFeedback(message.payload.messageId, state);
      },
      error: () => {
        this.toasterService.failed(
          "Sorry, we've got a problem while saving your feedback. Please try again!",
        );
      },
    });
  };

  private addFeedback = (messageId: string, state: ThumbsState): void => {
    const currentValue = this._feedbacks$.getValue() || {};
    const newValue = {
      ...currentValue,
      [messageId]: state,
    };

    this._feedbacks$.next(newValue);
  };
}
