import { Observable } from 'rxjs';

export const UserServiceContainerType = Symbol.for('UserService');

export interface IUserData {
  fingerPrintId?: string;
  userId?: string;
}

export interface IUserService {
  userData$: Observable<IUserData>;

  setUserData(data: Partial<IUserData>): void;
}
