import { inject, injectable } from 'inversify';
import { Observable, from, switchMap, take } from 'rxjs';
import { getUserHeaders } from '@/src/utils/common.utils';
import { IHttpService } from './http.types';
import * as userTypes from '../user';

@injectable()
export class FetchHttpService implements IHttpService {
  constructor(
    @inject(userTypes.UserServiceContainerType)
    private userService: userTypes.IUserService,
  ) {}

  public makeRequest<T>(
    input: RequestInfo,
    init: RequestInit = {},
  ): Observable<T> {
    return this.userService.userData$.pipe(
      take(1),
      switchMap((userData) => {
        const headers = getUserHeaders(userData);

        return from(
          fetch(input, {
            headers: {
              Accept: 'application/json',
              'Client-Date': new Date().toUTCString(),
              ...headers,
              ...(init.headers ? init.headers : {}),
            },
            ...init,
          }).then((result) => result.json() as T),
        );
      }),
    );
  }
}
