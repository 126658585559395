import {
  AssistantMessage,
  Product,
  ThumbsState,
  UserChatAction,
  UserChatActionMessage,
  UserProductCardAction,
  UserProductCardMessage,
} from 'charlie-workflows/types';
import { Observable } from 'rxjs';
import { SendMessageMetadata } from '../messages/messages.types';

export const MessagesApiServiceContainerType = Symbol.for('MessagesApiService');

export interface ICreateChatArgs {
  topic?: string;
  prompts?: {
    capsules?: string;
    conversation?: string;
    recommendations?: string;
    questions?: string;
  };
  promptExperience?: string;
  streamingProductCardCountExperience?: string;
  recommendationsPromptEngineVersion?: string;
  randomPromptExperimentFeatureFlagVariant?: string;

  recipientId?: string;
}

export interface IMessagesApiService {
  sendMessage(chatId: string, data: SendMessageMetadata): Observable<string>;

  sendAudioMessage(chatId: string, data: Blob): Observable<void>;

  sendMessageFeedback(
    message: AssistantMessage,
    state: ThumbsState,
  ): Observable<void>;

  sendProductCardAction(
    chatId: string,
    type: UserProductCardAction,
    productIds: string[],
    product?: Product,
  ): Observable<UserProductCardMessage>;

  sendUserChatActionMessage(
    chatId: string,
    type: UserChatAction,
  ): Observable<UserChatActionMessage>;

  createChat(data: ICreateChatArgs): Observable<{ id: string }>;

  sendAuthInfo(): Observable<void>;
}
