import {
  createContext,
  FC,
  useContext,
  useMemo,
  DependencyList,
  useEffect,
  useState,
} from 'react';
import { Container, interfaces } from 'inversify';
import { Observable } from 'rxjs';
import 'reflect-metadata';

import {
  IRecipientsApiService,
  RecipientsApiService,
  RecipientsApiServiceContainerType,
} from './recipients-api';
import {
  IMessagesApiService,
  MessagesApiService,
  MessagesApiServiceContainerType,
} from './messages-api';
import {
  IChatStorageService,
  MemoryChatService,
  ChatStorageServiceContainerType,
} from './chat-storage';
import {
  ISessionsService,
  SessionsService,
  SessionsServiceContainerType,
} from './sessions';
import {
  IStorageService,
  LocalStorageService,
  StorageServiceContainerType,
} from './storage';
import {
  FeatureFlagsService,
  FeatureFlagsServiceContainerType,
  IFeatureFlagsService,
} from './feature-flags';
import {
  FeedbackService,
  FeedbackServiceContainerType,
  IFeedbackService,
} from './feedback';
import {
  ModalServiceContainerType,
  StackModalService,
  IModalService,
} from './modal';
import {
  IToasterService,
  ToasterServiceContainerType,
  ToasterService,
} from './toaster';
import {
  IChatEventsService,
  ChatEventsServiceContainerType,
  SseChatEventsService,
} from './chat-events';
import {
  FingerprintUserService,
  UserServiceContainerType,
  IUserService,
} from './user';
import {
  MessagesService,
  IMessagesService,
  MessagesServiceContainerType,
} from './messages';
import {
  BreakpointService,
  BreakpointServiceContainerType,
  IBreakpointService,
} from './breakpoint';
import {
  AnalyticsService,
  AnalyticsServiceContainerType,
  IAnalyticsService,
} from './analytics';
import {
  AnalyticsApiService,
  AnalyticsApiServiceContainerType,
  IAnalyticsApiService,
} from './analytics-api';
import {
  WishlistsService,
  WishlistsServiceContainerType,
  IWishlistsService,
} from './wishlists';
import {
  WishlistsApiService,
  IWishlistsApiService,
  WishlistsApiServiceContainerType,
} from './wishlists-api';
import {
  AccountApiService,
  AccountApiServiceContainerType,
  IAccountApiService,
} from './account-api';
import { ProductApiService } from './product-api/product-api.service';
import {
  IProductApiService,
  ProductApiServiceContainerType,
} from './product-api/product-api.types';
import {
  ConversationsApiService,
  ConversationsApiServiceContainerType,
  IConversationsApiService,
} from './conversations-api';
import {
  IShareApiService,
  ShareApiService,
  ShareApiServiceContainerType,
} from './share-api';
import {
  FetchHttpService,
  HttpServiceContainerType,
  IHttpService,
} from './http';
import {
  ISessionsApiService,
  SessionsApiService,
  SessionsApiServiceContainerType,
} from './sessions-api';
import {
  RecipientsService,
  IRecipientsService,
  RecipientsServiceContainerType,
} from './recipients';

const sharedContainer = new Container();

const InversifyContext = createContext<interfaces.Container | null>(null);

const ContainerProvider: FC<{
  container: interfaces.Container;
  children?: React.ReactNode;
}> = ({ container, children }) => (
  <InversifyContext.Provider value={container}>
    {children}
  </InversifyContext.Provider>
);

function useInjectable<T>(type: interfaces.ServiceIdentifier<T>): T {
  const container = useContext(InversifyContext);
  if (container) {
    const service = useMemo<T>(() => container.get<T>(type), [container, type]);

    return service;
  } else {
    throw new Error(
      'useInjectable is being used before the container is initialized. You probably forgot to pass the container to InversifyContent',
    );
  }
}

/**
 * Hook to get the observable value
 * @param {Observable<T>} [dataSource] - Observable for subscribe
 * @param {T} [initialValue=null] - The initial value of the observable
 * @returns The observable value
 * @example
 * <caption>An example of use with an observable array.</caption>
 * const array = useSubscription(of([1, 2, 3]), []);
 */
function useSubscription<T>(
  dataSource: Observable<T>,
  initialValue: T | null = null,
  dependencies: DependencyList = [],
): T | null {
  const [data, setData] = useState<T | null>(initialValue);

  useEffect(() => {
    const subscriber = dataSource.subscribe(setData);
    return () => {
      subscriber.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return data;
}

export function bindToSharedContainer<T>(
  type: interfaces.ServiceIdentifier<T>,
  constructor: new (...args: never[]) => T,
) {
  sharedContainer.bind<T>(type).to(constructor).inSingletonScope();
}

sharedContainer
  .bind<IRecipientsService>(RecipientsServiceContainerType)
  .to(RecipientsService)
  .inSingletonScope();

sharedContainer
  .bind<IRecipientsApiService>(RecipientsApiServiceContainerType)
  .to(RecipientsApiService)
  .inSingletonScope();

sharedContainer
  .bind<IToasterService>(ToasterServiceContainerType)
  .to(ToasterService)
  .inSingletonScope();

sharedContainer
  .bind<IStorageService>(StorageServiceContainerType)
  .to(LocalStorageService)
  .inSingletonScope();

sharedContainer
  .bind<IModalService>(ModalServiceContainerType)
  .to(StackModalService)
  .inSingletonScope();

sharedContainer
  .bind<IMessagesApiService>(MessagesApiServiceContainerType)
  .to(MessagesApiService)
  .inSingletonScope();

sharedContainer
  .bind<ISessionsApiService>(SessionsApiServiceContainerType)
  .to(SessionsApiService)
  .inSingletonScope();

sharedContainer
  .bind<IFeedbackService>(FeedbackServiceContainerType)
  .to(FeedbackService)
  .inSingletonScope();

sharedContainer
  .bind<IMessagesService>(MessagesServiceContainerType)
  .to(MessagesService)
  .inSingletonScope();

sharedContainer
  .bind<IChatStorageService>(ChatStorageServiceContainerType)
  .to(MemoryChatService)
  .inSingletonScope();

sharedContainer
  .bind<ISessionsService>(SessionsServiceContainerType)
  .to(SessionsService)
  .inSingletonScope();

sharedContainer
  .bind<IChatEventsService>(ChatEventsServiceContainerType)
  .to(SseChatEventsService)
  .inSingletonScope();

sharedContainer
  .bind<IUserService>(UserServiceContainerType)
  .to(FingerprintUserService)
  .inSingletonScope();

sharedContainer
  .bind<IBreakpointService>(BreakpointServiceContainerType)
  .to(BreakpointService)
  .inSingletonScope();

sharedContainer
  .bind<IAnalyticsService>(AnalyticsServiceContainerType)
  .to(AnalyticsService)
  .inSingletonScope();

sharedContainer
  .bind<IAnalyticsApiService>(AnalyticsApiServiceContainerType)
  .to(AnalyticsApiService)
  .inSingletonScope();

sharedContainer
  .bind<IWishlistsApiService>(WishlistsApiServiceContainerType)
  .to(WishlistsApiService)
  .inSingletonScope();

sharedContainer
  .bind<IWishlistsService>(WishlistsServiceContainerType)
  .to(WishlistsService)
  .inSingletonScope();

sharedContainer
  .bind<IAccountApiService>(AccountApiServiceContainerType)
  .to(AccountApiService)
  .inSingletonScope();

sharedContainer
  .bind<IProductApiService>(ProductApiServiceContainerType)
  .to(ProductApiService)
  .inSingletonScope();

sharedContainer
  .bind<IConversationsApiService>(ConversationsApiServiceContainerType)
  .to(ConversationsApiService)
  .inSingletonScope();

sharedContainer
  .bind<IShareApiService>(ShareApiServiceContainerType)
  .to(ShareApiService)
  .inSingletonScope();

sharedContainer
  .bind<IHttpService>(HttpServiceContainerType)
  .to(FetchHttpService)
  .inSingletonScope();

sharedContainer
  .bind<IFeatureFlagsService>(FeatureFlagsServiceContainerType)
  .to(FeatureFlagsService)
  .inSingletonScope();

// const servicesMap = [
//   [RecipientsApiServiceContainerType, RecipientsApiService],
//   [ToasterServiceContainerType, ToasterService],
//   [StorageServiceContainerType, LocalStorageService],
//   [ModalServiceContainerType, StackModalService],
//   [MessagesApiServiceContainerType, MessagesApiService],
//   [FeedbackServiceContainerType, FeedbackService],
//   [MessagesServiceContainerType, MessagesService],
//   [ChatStorageServiceContainerType, MemoryChatService],
//   [SessionsServiceContainerType, MemorizedSessionsService],
//   [ChatEventsServiceContainerType, SseChatEventsService],
//   [UserServiceContainerType, FingerprintUserService],
//   [BreakpointServiceContainerType, BreakpointService],
//   [AnalyticsServiceContainerType, AnalyticsService],
//   [AnalyticsApiServiceContainerType, AnalyticsApiService],
//   [WishlistsApiServiceContainerType, WishlistsApiService],
//   [WishlistsServiceContainerType, WishlistsService],
//   [AccountApiServiceContainerType, AccountApiService],
//   [ProductApiServiceContainerType, ProductApiService],
//   [ConversationsApiServiceContainerType, ConversationsApiService],
//   [ShareApiServiceContainerType, ShareApiService],
//   [HttpServiceContainerType, FetchHttpService],
// ];

// servicesMap.forEach(([type, service]: any) => {
//   sharedContainer.bind<any>(type).to(service).inSingletonScope();
// });

export { sharedContainer, useInjectable, ContainerProvider, useSubscription };
