import { UserProvider, useUser } from '@auth0/nextjs-auth0/client';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import Script from 'next/script';
import postHog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Subscription } from 'rxjs';

import {
  ContainerProvider,
  sharedContainer,
  useInjectable,
} from '@/src/services';
import {
  AnalyticsServiceContainerType,
  IAnalyticsService,
} from '@/src/services/analytics/analytics.types';
import { ModalContainer } from '@/src/services/modal/modal-container.component';
import { ToasterContainer } from '@/src/services/toaster';
import {
  IUserData,
  IUserService,
  UserServiceContainerType,
} from '@/src/services/user/user.types';
import '@/styles/globals.scss';
import '@/styles/light.theme.scss';
import { useModal } from '@/src/services/modal';
import { FeatureChangesModal } from '@/src/components/modals/feature-changes-modal/feature-changes-modal.component';
import { LoadingLayout } from '@/src/components/layouts/loading-layout/loading-layout.component';

const inter = Inter({ subsets: ['latin'] });

if (typeof window !== 'undefined') {
  postHog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

// const allowedCookiePaths = ['/', '/privacy-policy', 'for-me'];

const AppWithAnalytics: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const { user, isLoading } = useUser();

  const { capturePageView } = useInjectable<IAnalyticsService>(
    AnalyticsServiceContainerType,
  );
  const { userData$, setUserData } = useInjectable<IUserService>(
    UserServiceContainerType,
  );

  const { open, close } = useModal((props) => (
    <FeatureChangesModal
      onGotIt={() => {
        // localStorage.setItem(APP_STORAGE_KEY, currentAppVersion);
        close();
      }}
    />
  ));

  // useEffect(() => {
  //   const userAppV = localStorage.getItem(APP_STORAGE_KEY);

  //   if (!userAppV || userAppV !== currentAppVersion) {
  //     open();
  //   }
  // }, []);

  useEffect(() => {
    if (user && user.sub) {
      setUserData({ userId: user.sub });
    }
  }, [user, setUserData]);

  useEffect(() => {
    let subscription: Subscription | null = null;
    let userData: IUserData | null = null;

    const handleRouteChange = (url: string) => {
      if (userData) {
        capturePageView(url);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    subscription = userData$.subscribe((data) => {
      if (data) {
        userData = data;
      }
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }

      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [userData$, router, capturePageView]);

  return (
    <PostHogProvider client={postHog}>
      <Component {...pageProps} />
      {isLoading ? <LoadingLayout /> : null}

      <ModalContainer />
      <ToasterContainer>
        <ToastContainer />
      </ToasterContainer>
    </PostHogProvider>
  );
};

export default function App(props: AppProps) {
  return (
    <>
      <style jsx global>{`
        html,
        button {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>

      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${
            process.env.NEXT_PUBLIC_GTM_AUTH || 'OsiubkGjmpooUwtrU9biBA'
          }&gtm_preview=${
            process.env.NEXT_PUBLIC_GTM_ENV_NUMBER || 'env-29'
          }&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TMX9RQ78');`,
        }}
      />

      <UserProvider>
        <ContainerProvider container={sharedContainer}>
          <AppWithAnalytics {...props} />
        </ContainerProvider>
      </UserProvider>

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${
          process.env.NEXT_PUBLIC_GA_ID || 'G-0J84MMS001'
        }`}
      />

      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${
              process.env.NEXT_PUBLIC_GA_ID || 'G-0J84MMS001'
            }', {
              page_path: window.location.pathname,
            });

            window.gtag = gtag;
          `,
        }}
      />

      {/* <Script
        id="wisepops"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,i,s,e){window[w]=window[w]||function(){(window[w].q=window[w].q||[]).push(arguments)};window[w].l=Date.now();s=document.createElement('script');e=document.getElementsByTagName('script')[0];s.defer=1;s.src=i;e.parentNode.insertBefore(s, e)})
          ('wisepops', 'https://wisepops.net/loader.js?v=2&h=stmN6BQtJL');
          `,
        }}
      /> */}
    </>
  );
}
