import { inject, injectable } from 'inversify';
import { combineLatest } from 'rxjs';
import postHogLibrary, { Properties } from 'posthog-js';

import { AnalyticsEventPriority, IAnalyticsService } from './analytics.types';
import * as analyticsApiServiceTypes from '../analytics-api/analytics-api.types';
import * as userServiceTypes from '../user/user.types';
import * as chatStorageServiceTypes from '../chat-storage/chat-storage.types';
import { Gtag } from './gtag.utils';

function transformToAllDashes(input: string): string {
  return input.replace(/_/g, '-');
}

@injectable()
export class AnalyticsService implements IAnalyticsService {
  constructor(
    @inject(analyticsApiServiceTypes.AnalyticsApiServiceContainerType)
    private analyticApiService: analyticsApiServiceTypes.IAnalyticsApiService,
    @inject(userServiceTypes.UserServiceContainerType)
    private userService: userServiceTypes.IUserService,
    @inject(chatStorageServiceTypes.ChatStorageServiceContainerType)
    private chatStorageService: chatStorageServiceTypes.IChatStorageService,
  ) {
    userService.userData$.subscribe((data) => {
      if (data.userId) {
        const gtag = new Gtag();
        gtag.setUserId(data.userId);
      }
    });
  }

  public trackEvent = (
    eventName: string,
    data?: Record<string, unknown>,
    priority: AnalyticsEventPriority = 'regular',
  ): void => {
    combineLatest([
      this.userService.userData$,
      this.chatStorageService.streamParams$,
    ]).subscribe(([userData, streamParams]) => {
      const eventData = {
        data,
        deviceId: userData.fingerPrintId,
        userId: userData.userId,
        sessionId: streamParams.chatId,
        clientTimestamp: new Date().toISOString(),
      };

      try {
        this.analyticApiService.trackEvent(eventName, eventData);
      } catch (error) {
        console.error(error);
      }

      postHogLibrary.capture(transformToAllDashes(eventName), {
        data: eventData,
        userAgent: window.navigator.userAgent,
      } as Properties);
    });

    if (typeof window !== 'undefined' && priority === 'conversion') {
      const gtag = new Gtag();
      gtag.event(eventName, data || undefined);
    }
  };

  public capturePageView = (url: string): void => {
    if (typeof window !== 'undefined') {
      const gtag = new Gtag();
      gtag.pageView(url);
    }
  };
}
