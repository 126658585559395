import {
  AssistantGenerativeButtonsAppendResponse,
  AssistantGenerativeButtonsResponse,
  AssistantMarkdownAppendResponse,
  AssistantMarkdownResponse,
  AssistantMessage,
  ProceduralMessage,
  Product,
  RecipientMetadata,
  RestoreMessages,
  UserChatAction,
  UserChatActionMessage,
  UserMessage,
  UserProductCardAction,
  UserProductCardMessage,
} from 'charlie-workflows/types';
import { inject, injectable } from 'inversify';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  filter,
  first,
  map,
} from 'rxjs';
import { v4 } from 'uuid';
import { findLastIndex } from '@/src/utils/array.utils';
import { IAnyMessage, ICombinedMessages } from '../chat-storage';
import * as messagesApiTypes from '../messages-api/messages-api.types';
import { IMessagesService, SendMessageMetadata } from './messages.types';

@injectable()
export class MessagesService implements IMessagesService {
  private _messages$: BehaviorSubject<ICombinedMessages | null> =
    new BehaviorSubject<ICombinedMessages | null>(null);

  // public messages$: Observable<IMessagesGroup[] | null> = this._messages$.pipe(
  //   map((value) => (value ? Object.values(value).flat() : null)),
  // );
  public messages$: Observable<IAnyMessage[] | null> = this._messages$.pipe(
    map((value) => (value ? Object.values(value).flat() : null)),
  );

  private storeSubscription: Subscription | null = null;

  constructor(
    @inject(messagesApiTypes.MessagesApiServiceContainerType)
    private messagesApiService?: messagesApiTypes.IMessagesApiService,
  ) {}

  public initialize = (): void => {};

  public destroy = (): void => {
    this.storeSubscription?.unsubscribe();
    this._messages$.next(null);
  };

  public getMessagesBySessionId = (
    id: string,
  ): Observable<IAnyMessage[] | null> => {
    return this.messages$.pipe(
      filter((messages) => Boolean(messages)),
      first(),
      map((messages: IAnyMessage[] | null) =>
        (messages || []).filter(({ payload }) => payload.requestId === id),
      ),
    );
  };

  public sendMessage = (chatId: string, details: SendMessageMetadata): void => {
    // const generatedId = v4();
    // const userMessage: UserMessage = {
    //   type: 'userMessage',
    //   payload: {
    //     generativeButtons: details.generativeButtons,
    //     message: details.content,
    //     image: details.image,

    //     messageId: generatedId,
    //     requestId: chatId,
    //     timestamp: Date.now(),
    //   },
    // };

    // this.addMessage(userMessage);

    this.messagesApiService
      ?.sendMessage(chatId, details)
      .subscribe((messageId: string) => {
        // this.updateId(generatedId, messageId);
      });
  };

  public sendAudioMessage = (content: Blob, chatId: string): void => {
    const generatedId = v4();
    const userMessage: UserMessage = {
      type: 'userMessage',
      payload: {
        message: 'Audio message',
        messageId: generatedId,
        requestId: chatId,
        timestamp: Date.now(),
      },
    };

    this.addMessage(userMessage);

    this.messagesApiService?.sendAudioMessage(chatId, content).subscribe(() => {
      // console.log(Message send)
    });
  };

  public sendProductMessage = (
    type: UserProductCardAction,
    chatId: string,
    productIds: string[],
    product?: Product,
  ): void => {
    this.messagesApiService
      ?.sendProductCardAction(chatId, type, productIds, product)
      .subscribe((message: UserProductCardMessage) => {
        this.addMessage(message);
      });
  };

  public sendUserChatActionMessage = (
    type: UserChatAction,
    chatId: string,
  ): void => {
    this.messagesApiService
      ?.sendUserChatActionMessage(chatId, type)
      .subscribe((message: UserChatActionMessage) => {
        this.addMessage(message);
      });
  };

  public handleRestoreMessage = (message: RestoreMessages) => {
    const messagesValue = this._messages$.getValue();

    /* condition for checking if SSE is reconnect to avoid double messages */
    if (!messagesValue) {
      message.payload.messages.forEach((msg) => {
        this.handleAppearedMessage(msg);
      });
    }
  };

  public handleAppearedMessage = (
    message: AssistantMessage | UserMessage | ProceduralMessage,
  ) => {
    const { type } = message;

    switch (type) {
      case 'userMessage':
        this.addMessage(message);
        break;
      case 'markdownResponse':
        this.handleMarkdown(message);
        break;
      case 'markdownAppendResponse':
        this.handleMarkdownAppend(message);
        break;
      case 'generativeButtonsResponse':
        this.handleGenerativeButtons(message);
        break;
      case 'generativeButtonsAppendResponse':
        this.handleGenerativeButtonsAppend(message);
        break;
      case 'recipientMetadata':
        this.handleRecipientMetadata(message);
        break;
      case 'multipleProductResponse':
      case 'singleProductResponse':
      case 'markDownResponseSuggestion':
      case 'graphResponse':
      case 'personalityResponse':
      case 'giftTypeResponse':
      case 'stepSuggestionsResponse':
      case 'predictMultipleProductResponse':
      case 'predictMarkDownResponseSuggestion':
        this.addMessage(message);
        break;
    }
  };

  public addMessage = (message: IAnyMessage): void => {
    const { messageId } = message.payload;
    const currentValue = this._messages$.getValue() || {};
    const newValue = {
      ...currentValue,
      [messageId]: (currentValue[messageId] || []).concat([message]),
    };
    this._messages$.next(newValue);
  };

  public handleMarkdown = (message: AssistantMarkdownResponse): void => {
    const { payload } = message;
    if (payload.markdown && payload.markdown.length > 0) {
      this.addMessage(message);
    }
  };

  public handleMarkdownAppend = (
    message: AssistantMarkdownAppendResponse,
  ): void => {
    const { payload } = message;
    if (payload.markdown && payload.markdown.length) {
      const { messageId } = payload;
      const currentMessagesValue = this._messages$.getValue();

      let messagesForId: IAnyMessage[] = [];

      if (currentMessagesValue) {
        messagesForId = currentMessagesValue[messageId] || [];
      }

      const existingMessageIndex = findLastIndex(
        messagesForId,
        (item) => item.type === 'markdownResponse',
      );

      const existingMessage = messagesForId[existingMessageIndex];

      if (!existingMessage) {
        this.addMessage({ ...message, type: 'markdownResponse' });
      } else {
        if (existingMessage.type === 'markdownResponse') {
          this.updateMarkdown(messageId, {
            ...message,
            payload: {
              ...message.payload,
              messageId,
              markdown:
                existingMessage.payload.markdown + message.payload.markdown,
            },
            type: 'markdownResponse',
          });
        }
      }
    }
  };

  public handleGenerativeButtons = (
    message: AssistantGenerativeButtonsResponse,
  ): void => {
    const { payload } = message;
    const conditions = [
      payload.titleMarkdown && payload.titleMarkdown.length,
      payload.contentMarkdown && payload.contentMarkdown.length,
      payload.buttons && payload.buttons.length,
    ];
    if (conditions.some(Boolean)) {
      this.addMessage(message);
    }
  };

  public handleGenerativeButtonsAppend = (
    message: AssistantGenerativeButtonsAppendResponse,
  ): void => {
    const { payload } = message;
    const conditions = [
      payload.titleMarkdown && payload.titleMarkdown.length,
      payload.contentMarkdown && payload.contentMarkdown.length,
      payload.buttons && payload.buttons.length,
    ];
    if (conditions.some(Boolean)) {
      const { messageId } = payload;
      const currentMessagesValue = this._messages$.getValue();

      let messagesForId: IAnyMessage[] = [];

      if (currentMessagesValue) {
        messagesForId = currentMessagesValue[messageId] || [];
      }

      const existingMessageIndex = findLastIndex(
        messagesForId,
        (item) => item.type === 'generativeButtonsResponse',
      );

      const existingMessage = messagesForId[existingMessageIndex];

      if (!existingMessage) {
        this.addMessage({ ...message, type: 'generativeButtonsResponse' });
      } else {
        if (existingMessage.type === 'generativeButtonsResponse') {
          const [
            titleMarkdownCondition,
            contentMarkdownCondition,
            buttonsCondition,
          ] = conditions;

          const payload = {
            ...message.payload,
            messageId,
            ...(titleMarkdownCondition
              ? {
                  titleMarkdown:
                    (existingMessage.payload.titleMarkdown || '') +
                    message.payload.titleMarkdown,
                }
              : null),
            ...(contentMarkdownCondition
              ? {
                  contentMarkdown:
                    (existingMessage.payload.contentMarkdown || '') +
                    message.payload.contentMarkdown,
                }
              : null),
            ...(buttonsCondition
              ? {
                  buttons: [
                    ...existingMessage.payload.buttons,
                    ...message.payload.buttons,
                  ],
                }
              : null),
          };

          this.updateMarkdown(messageId, {
            ...message,
            payload,
            type: 'generativeButtonsResponse',
          });
        }
      }
    }
  };

  private handleRecipientMetadata(message: RecipientMetadata): void {
    const existingValuesCopy = Object.assign({}, this._messages$.getValue());

    const existingRecipientMetadata = Object.values(existingValuesCopy)
      .reduce((acc, msgs) => [...acc, ...msgs], [])
      .find((item) => item.type === 'recipientMetadata') as
      | RecipientMetadata
      | undefined;

    if (existingRecipientMetadata) {
      existingRecipientMetadata.payload = {
        messageId: existingRecipientMetadata.payload.messageId,
        requestId: existingRecipientMetadata.payload.requestId,
        timestamp: existingRecipientMetadata.payload.timestamp,
        attached: message.payload.attached,
        recipient: {
          ...existingRecipientMetadata.payload.recipient,
          ...message.payload.recipient,
        },
      };
      this._messages$.next(existingValuesCopy);
    } else {
      this.addMessage(message);
    }
  }

  private updateMarkdown = (messageId: string, message: IAnyMessage): void => {
    const existingValuesCopy = Object.assign({}, this._messages$.getValue());
    const existingMessagesForId = existingValuesCopy[messageId];

    if (!existingMessagesForId) {
      throw new Error(
        `Tried to update a message that doesn't exist: ${messageId}`,
      );
    }

    const currentMessageIndex = findLastIndex(
      existingMessagesForId,
      (item) => item.type === message.type,
    );

    if (currentMessageIndex < 0) {
      throw new Error(
        'Message update error: the updated message type does not match with a previous type',
      );
    }

    existingValuesCopy[messageId] = existingMessagesForId.map((item, index) =>
      index === currentMessageIndex ? message : item,
    );

    this._messages$.next(existingValuesCopy);
  };
}
