import { FunctionComponent, useRef } from 'react';
import {
  Confirm,
  ConfirmModalProps,
  Slide,
} from '@/src/shared/ui-kit/components/modal';
import { useInjectable } from '..';
import {
  ModalServiceContainerType,
  IModalService,
  BaseModalProps,
} from './modal.types';

export interface UseModalResult<T> {
  close: () => void;
  open: (props: T) => void;
}

export function useModal<T>(
  component: FunctionComponent<T & BaseModalProps>,
): UseModalResult<T & BaseModalProps> {
  const { openModal } = useInjectable<IModalService>(ModalServiceContainerType);
  const closeRef = useRef<(() => void) | null>(null);

  return {
    open: (props: T) => {
      const { onClose } = openModal<T>(component, props);
      closeRef.current = onClose;
    },
    close: () => {
      if (closeRef.current) {
        closeRef.current();
      } else {
        throw new Error('Tried to close a modal which has not been opened');
      }
    },
  };
}

export function useConfirmModal(): UseModalResult<ConfirmModalProps> {
  return useModal(Confirm);
}

export function useSlideModal<T>(
  Component: FunctionComponent<T & BaseModalProps>,
): UseModalResult<T & BaseModalProps> {
  const { openModal } = useInjectable<IModalService>(ModalServiceContainerType);
  const closeRef = useRef<(() => void) | null>(null);
  return {
    open: (props: T = {} as T) => {
      const { onClose } = openModal<T>(
        () => (
          <Slide onClose={onClose}>
            <Component onClose={onClose} {...props}></Component>
          </Slide>
        ),
        {
          className: 'fullscreenContainerOverlay',
          bodyClassName: 'fullscreenContainer',
          backdropClassName: 'backdropRecipientModal',
          ...props,
        },
      );
      closeRef.current = onClose;
    },
    close: () => {
      if (closeRef.current) {
        closeRef.current();
      } else {
        throw new Error('Tried to close a modal which has not been opened');
      }
    },
  };
}
