import { SessionEntity } from 'charlie-workflows/contracts/sessions';
import { Observable } from 'rxjs';

export const SessionsServiceContainerType = Symbol.for('SessionsService');

export interface ISessionsService {
  sessions$: Observable<SessionEntity[]>;
  getSessions(): Observable<SessionEntity[]>;

  updateSession(
    sessionId: string,
    name: string,
  ): Observable<{ session: SessionEntity }>;
  shallowUpdateSession(sessionId: string, name: string): void;
  deleteSession(sessionId: string): Observable<void>;
}
