import { Observable, map, of } from 'rxjs';
import { inject, injectable } from 'inversify';
import { Product } from 'charlie-workflows/types';
import { IWishList } from 'charlie-workflows/workflows/users/wishlist';
import { IWishlistsApiService } from './wishlists-api.types';
import * as httpTypes from '../http';

@injectable()
export class WishlistsApiService implements IWishlistsApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public getAll = (): Observable<IWishList[]> => {
    return this.httpService
      .makeRequest<{ wishlists: Array<IWishList> }>('/api/wishlists', {
        method: 'GET',
      })
      .pipe(map((result) => result.wishlists));
  };

  public getWishlistByRecipient = (
    recipientId: string,
  ): Observable<IWishList | null> => {
    return this.httpService
      .makeRequest<{ wishlist: IWishList | null }>(
        `/api/wishlists/${recipientId}`,
        {
          method: 'GET',
        },
      )
      .pipe(map((result) => result.wishlist));
  };

  public getWishlistById = (id: string): Observable<IWishList> => {
    return this.httpService
      .makeRequest<{ wishlist: IWishList }>(`/api/wishlists/${id}`, {
        method: 'GET',
      })
      .pipe(map((data) => data.wishlist));
  };

  public postWishlist = (
    wishlistName: string,
    recipientId?: string,
  ): Observable<IWishList> => {
    return this.httpService
      .makeRequest<{ wishlist: IWishList }>('/api/wishlists', {
        method: 'POST',
        body: JSON.stringify({
          name: wishlistName,
          recipientId,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((data) => data.wishlist));
  };

  public postProduct = (
    wishlistId: string,
    products: Product[],
  ): Observable<IWishList> => {
    return this.httpService.makeRequest<IWishList>(
      `/api/wishlists/${wishlistId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product: products[0],
        }),
      },
    );
  };

  public postProductForRecipient: (
    recipientId: string,
    products: Product[],
  ) => Observable<IWishList> = (recipientId, products) => {
    return this.httpService.makeRequest<IWishList>(
      `/api/wishlists/recipient/${recipientId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product: products[0],
        }),
      },
    );
  };

  public deleteWishlist = (wishlistId: string): Observable<void> => {
    return this.httpService.makeRequest<void>(`/api/wishlists/${wishlistId}`, {
      method: 'DELETE',
    });
  };

  public deleteProduct = (
    wishlistId: string,
    product: Product,
  ): Observable<IWishList> => {
    return this.httpService
      .makeRequest<{ wishlist: IWishList }>(
        `/api/wishlists/${wishlistId}/product/${product.payload.productId}`,
        {
          method: 'DELETE',
        },
      )
      .pipe(map((data) => data.wishlist));
  };
}
