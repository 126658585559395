export enum Celebrations {
	MothersDay = 'mothers-day',
	FathersDay = 'fathers-day',
	Birthday = 'birthday',
	BachelorParty = 'bachelor-party',
	BacheloretteParty = 'bachelorette-party',
	Tifu = 'tifu',
	NewBaby = 'new-baby',
	Housewarming = 'housewarming',
	Graduation = 'graduation',
	Congratulations = 'congratulations',
	Engagement = 'engagement',
	Retirement = 'retirement',
	JustBecause = 'just-because',
	Anniversary = 'anniversary',
	TreatYourself = 'treat-yourself'
}

export enum OccasionTopics {
	Shopping = 'shopping',
	Gifts = 'gifts',
	Gifting = 'gifting',
	Music = 'music',
	Books = 'books',
	CarSeats = 'car-seats',
	MealPlanning = 'meal-planning'
}

export type Occasion = OccasionTopics | Celebrations;
