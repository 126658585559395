import { BehaviorSubject } from 'rxjs';
import { IBreakpointService } from './breakpoint.types';
import { injectable } from 'inversify';

@injectable()
export class BreakpointService implements IBreakpointService {
  public _breakpoints$ = new BehaviorSubject<number | null>(null);
  public breakpoints$ = this._breakpoints$.asObservable();
  public initialize = () => {
    this._breakpoints$.next(window.innerWidth);

    window.addEventListener('resize', this.widthListener);
  };

  private widthListener = () => {
    this._breakpoints$.next(window.innerWidth);
  };

  public destroy = () => {
    window.removeEventListener('resize', this.widthListener);
  };
}
