import {
  AssistantGenerativeButtonsAppendResponse,
  AssistantGenerativeButtonsResponse,
  AssistantMarkdownAppendResponse,
  AssistantMarkdownResponse,
  AssistantMessage,
  ProceduralMessage,
  Product,
  RestoreMessages,
  UserMessage,
  UserProductCardMessage,
  UserProductCardAction,
  UserChatAction,
  UserChatActionMessage,
} from 'charlie-workflows/types';
import { Observable } from 'rxjs';
// import { IMessagesGroup } from '@/src/shared/ui-kit/components/chat/utils/chat.utils';
import { IAnyMessage } from '../chat-storage';

export const MessagesServiceContainerType = Symbol.for('MessagesService');

export type SendMessageMetadata = {
  content: string;
  generativeButtons?: string[];
  image?: File;
};

export interface IMessagesService {
  // messages$: Observable<IMessagesGroup[] | null>;
  messages$: Observable<IAnyMessage[] | null>;
  initialize(): void;
  destroy(): void;
  sendMessage(chatId: string, details: SendMessageMetadata): void;
  sendAudioMessage(content: Blob, chatId: string): void;
  sendProductMessage(
    type: UserProductCardAction,
    chatId: string,
    productId: string[],
    product?: Product,
  ): void;
  sendUserChatActionMessage(type: UserChatAction, chatId: string): void;
  addMessage(message: IAnyMessage): void;
  getMessagesBySessionId(id: string): Observable<IAnyMessage[] | null>;
  handleRestoreMessage(message: RestoreMessages): void;
  handleAppearedMessage(
    message:
      | AssistantMessage
      | UserMessage
      | UserProductCardMessage
      | UserChatActionMessage
      | ProceduralMessage,
  ): void;
  handleMarkdown(message: AssistantMarkdownResponse): void;
  handleMarkdownAppend(message: AssistantMarkdownAppendResponse): void;
  handleGenerativeButtons(message: AssistantGenerativeButtonsResponse): void;
  handleGenerativeButtonsAppend(
    message: AssistantGenerativeButtonsAppendResponse,
  ): void;
}
