import { Modal } from '@/src/shared/ui-kit/components/modal';
import styles from './feature-changes-modal.module.scss';

interface IFeatureChangesParams {
  onClose?: () => void;
  onGotIt?: () => void;
}

export const FeatureChangesModal: React.FC<IFeatureChangesParams> = ({
  onGotIt,
}) => {
  return (
    <Modal.Body
      className={styles.container}
      onClick={(event) => event.stopPropagation()}
    >
      <h2 className={styles.header}>Feature update</h2>

      <section className={styles.section}>
        <h3 className={`${styles.featureTitle} ${styles.savedThreadsFeature}`}>
          Saved Threads
        </h3>
        <p className={styles.featureContent}>
          Access all of your gift searches from the top menu to pick up where
          you left off on previous searches!
        </p>
      </section>

      <button className={styles.gotItButton} onClick={onGotIt}>
        Got it!
      </button>
    </Modal.Body>
  );
};
