import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';
import * as httpTypes from '../http';
import { IShareApiService } from './share-api.types';

@injectable()
export class ShareApiService implements IShareApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public share = (streamId: string): Observable<{ id: string }> => {
    return this.httpService.makeRequest<{ id: string }>('/api/share', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stream: streamId,
      }),
    });
  };
}
