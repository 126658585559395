import { Observable } from 'rxjs';
import { Product } from 'charlie-workflows/types';
import { IWishList } from 'charlie-workflows/workflows/users/wishlist';

export const WishlistsApiServiceContainerType = Symbol.for(
  'WishlistsApiService',
);

export interface IWishlistsApiService {
  getAll: () => Observable<IWishList[]>;
  getWishlistById: (id: string) => Observable<IWishList>;
  postWishlist: (
    wishlistName: string,
    recipientId?: string,
  ) => Observable<IWishList>;
  postProduct: (
    wishlistId: string,
    products: Product[],
  ) => Observable<IWishList>;
  postProductForRecipient: (
    recipientId: string,
    products: Product[],
  ) => Observable<IWishList>;
  getWishlistByRecipient: (recipientId: string) => Observable<IWishList | null>;
  deleteWishlist: (wishlistId: string) => Observable<void>;
  deleteProduct: (
    wishlistId: string,
    product: Product,
  ) => Observable<IWishList>;
}
