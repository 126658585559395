import { RecipientEntity } from '../contracts';
import { Device } from './device';
import { Product, SuggestedProductId } from './products';

export interface ProceduralMessage {
	type: 'start';
	payload: {
		timestamp: number;
	};
}

export enum PredefinedBusyMessages {
	ProcessingRequest = ' ',
	CollectingProducts = ' '
}

export interface BusyMessage {
	type: 'busyMessage';
	payload: {
		requestId: string;
		messageId: string;
		message: PredefinedBusyMessages | string;
		showLoading?: boolean;
		timestamp: number;
	};
}

export interface UserMessage {
	type: 'userMessage';
	payload: {
		userId?: string;
		userFingerprintId?: string;
		messageId: string;
		requestId: string;
		timestamp: number;
		device?: Partial<Device>;
		userInfo?: any;

		message: string;
		generativeButtons?: string[];
		image?: string;
	};
}

export type ThumbsState = 'thumbs-down' | 'thumbs-up' | 'thumbs-neutral';

export type UserProductCardAction =
	| 'click'
	| 'wishlist-save'
	| 'show-else'
	| 'show-more'
	| ThumbsState;

export type UserChatAction = 'show-more-step-suggestions';

export interface UserChatActionMessage {
	type: 'userChatActionMessage';
	payload: {
		userId?: string;
		userFingerprintId?: string;
		messageId: string;
		requestId: string;
		timestamp: number;
		actionType: UserChatAction;
	};
}

export interface UserProductCardMessage {
	type: 'userProductCardMessage';
	payload: {
		userId?: string;
		userFingerprintId?: string;
		messageId: string;
		requestId: string;
		productId: SuggestedProductId | SuggestedProductId[];
		product?: Product;
		timestamp: number;
		interactionType: UserProductCardAction;
		userInfo?: any;
	};
}

export interface AssistantMarkdownResponseSuggestion {
	type: 'markDownResponseSuggestion';
	payload: {
		messageId: string;
		requestId: string;
		suggestions: string[];
		timestamp: number;
	};
}

export interface AssistantMarkdownResponse {
	type: 'markdownResponse';
	payload: {
		messageId: string;
		requestId: string;
		markdown: string;
		timestamp: number;
	};
}

export interface AssistantGraphResponse {
	type: 'graphResponse';
	payload: {
		messageId: string;
		requestId: string;
		configuration: string;
		timestamp: number;
	};
}

export interface AssistantMarkdownAppendResponse {
	type: 'markdownAppendResponse';
	payload: {
		messageId: string;
		requestId: string;
		markdown: string;
		timestamp: number;
	};
}

export interface AssistantMarkdownResponseFullText {
	type: 'markdownResponseFullText';
	payload: {
		messageId: string;
		requestId: string;
		markdown: string;
		timestamp: number;
	};
}

// {deprecated}
// export interface AssistantRecipientResponse {
// 	type: 'recipientResponse';
// 	payload: {
// 		messageId: string;
// 		requestId: string;
// 		markdown: string;
// 		timestamp: number;
// 	};
// }

export interface AssistantGenerativeButtonsResponse {
	type: 'generativeButtonsResponse';
	payload: {
		messageId: string;
		requestId: string;
		buttons: string[];
		contentMarkdown?: string;
		titleMarkdown?: string;
		timestamp: number;
	};
}

export interface AssistantGenerativeButtonsAppendResponse {
	type: 'generativeButtonsAppendResponse';
	payload: {
		messageId: string;
		requestId: string;
		buttons: string[];
		titleMarkdown?: string;
		contentMarkdown?: string;
		timestamp: number;
	};
}

export interface ProductSearchResult {
	purchaseLink: string;
	imageURL: string;
}

export interface AssistantSingleProductResponse {
	type: 'singleProductResponse';
	payload: {
		messageId: string;
		requestId: string;
		product: Product;
		timestamp: number;
	};
}

export interface AssistantMultipleProductResponse {
	type: 'multipleProductResponse';
	payload: {
		messageId: string;
		requestId: string;
		products: Product[];
		timestamp: number;
	};
}

export interface RestoreMessages {
	type: 'restoreMessages';
	payload: {
		messages: (AssistantMessage | UserMessage | ProceduralMessage)[];
		timestamp: number;
	};
}

export interface ChatMetadata {
	type: 'chatMetadata';
	payload: {
		messageId: string;
		title?: string;
		description?: string;
		productMatch?: number;
		requestId: string;
		timestamp: number;
	};
}

export interface RecipientMetadata {
	type: 'recipientMetadata';
	payload: {
		messageId: string;
		recipient: Partial<RecipientEntity>;
		requestId: string;
		timestamp: number;
		attached?: boolean;
	};
}

export interface PersonalityResponse {
	type: 'personalityResponse';
	payload: {
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface GiftTypeResponse {
	type: 'giftTypeResponse';
	payload: {
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface StepSuggestionsResponse {
	type: 'stepSuggestionsResponse';
	payload: {
		suggestions: string[];
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface AssistantResponseComplete {
	type: 'responseComplete';
	payload: {
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface TipPopupResponse {
	type: 'tipPopup';
	payload: {
		content: 'provideDetails' | 'saveIt';
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface ActionPopupResponse {
	type: 'actionPopup';
	payload: {
		content: 'signInPromptAction';
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface PredictMultipleProductResponse {
	type: 'predictMultipleProductResponse';
	payload: {
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface PredictStepSuggestionsResponse {
	type: 'predictStepSuggestionsResponse';
	payload: {
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export interface PredictMarkDownResponseSuggestion {
	type: 'predictMarkDownResponseSuggestion';
	payload: {
		messageId: string;
		requestId: string;
		timestamp: number;
	};
}

export type SystemEvents =
	| ChatMetadata
	| RecipientMetadata
	| TipPopupResponse
	| ActionPopupResponse;

export type AssistantMessage =
	| BusyMessage
	| SystemEvents
	| AssistantGraphResponse
	| AssistantMarkdownResponse
	| AssistantMarkdownAppendResponse
	| PredictMultipleProductResponse
	| PredictStepSuggestionsResponse
	| PredictMarkDownResponseSuggestion
	| AssistantMarkdownResponseSuggestion
	| AssistantSingleProductResponse
	| AssistantMultipleProductResponse
	| AssistantMarkdownResponse
	| AssistantMarkdownResponseFullText
	| AssistantResponseComplete
	| AssistantGenerativeButtonsResponse
	| AssistantGenerativeButtonsAppendResponse
	| PersonalityResponse
	| GiftTypeResponse
	| StepSuggestionsResponse;
