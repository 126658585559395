export const AnalyticsServiceContainerType = Symbol.for('AnalyticsService');

export type AnalyticsEventPriority = 'conversion' | 'regular';

export interface IAnalyticsService {
  trackEvent(
    event: string,
    data?: Record<string, unknown>,
    priority?: AnalyticsEventPriority,
  ): void;
  capturePageView(url: string): void;
}
