import { inject, injectable } from 'inversify';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { SessionEntity } from 'charlie-workflows/contracts/sessions';

import * as sessionsApiTypes from '../sessions-api';
import { ISessionsService } from './sessions.types';

@injectable()
export class SessionsService implements ISessionsService {
  private _sessions$: BehaviorSubject<SessionEntity[]> = new BehaviorSubject<
    SessionEntity[]
  >([]);
  public sessions$: Observable<SessionEntity[]> = this._sessions$
    .asObservable()
    .pipe(
      map((sessionsArray) =>
        (sessionsArray || []).sort((first, second) => {
          const firstDate = new Date(first?.timestamp).getTime();
          const secondDate = new Date(second?.timestamp).getTime();

          return secondDate - firstDate;
        }),
      ),
    );

  constructor(
    @inject(sessionsApiTypes.SessionsApiServiceContainerType)
    private sessionsApiService: sessionsApiTypes.ISessionsApiService,
  ) {}

  public getSessions = (): Observable<SessionEntity[]> => {
    return this.sessionsApiService.retrieveSessions().pipe(
      tap((value) => {
        if (value) {
          this._sessions$.next(value);
        }
      }),
    );
  };

  public updateSession = (
    sessionId: string,
    name: string,
  ): Observable<{ session: SessionEntity }> => {
    return this.sessionsApiService.updateSession(sessionId, name).pipe(
      tap(({ session }) => {
        const existingSessionsValue = this._sessions$.getValue().slice();
        const index = existingSessionsValue.findIndex(
          (item) => item.id === sessionId,
        );
        existingSessionsValue[index] = session;
        this._sessions$.next(existingSessionsValue);
      }),
    );
  };

  public shallowUpdateSession = (sessionId: string, name: string): void => {
    const existingSessionsValue = this._sessions$.getValue().slice();
    const index = existingSessionsValue.findIndex(
      (item) => item.id === sessionId,
    );
    const existingSession = existingSessionsValue[index];

    if (existingSession) {
      existingSessionsValue[index] = { ...existingSession, name };
      this._sessions$.next(existingSessionsValue);
    }
  };

  public deleteSession = (sessionId: string): Observable<void> => {
    const existingSessions = this._sessions$.getValue();
    return this.sessionsApiService.deleteSession(sessionId).pipe(
      tap(() => {
        const newValue = existingSessions.filter(
          (item) => item.id !== sessionId,
        );
        this._sessions$.next(newValue);
      }),
    );
  };
}
