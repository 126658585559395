import {
  PromptEditType,
  SessionPromptMetadata,
} from 'charlie-workflows/contracts/prompts';
import { Observable } from 'rxjs';

export const ConversationsApiServiceContainerType = Symbol.for(
  'ConversationsApiService',
);

export interface IConversationsApiService {
  branchConversation(sharedId: string): Observable<string>;
  getUserPrompts(): Observable<Record<PromptEditType, string | undefined>>;
  resetToDefaultPrompts(): Observable<
    Record<PromptEditType, string | undefined>
  >;
  saveConversationPrompt(
    promptMetadata: Pick<SessionPromptMetadata, 'promptType' | 'prompt'>,
  ): Observable<Record<PromptEditType, string | undefined>>;
}
