import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { injectable } from 'inversify';
import { IStorageService, KnownStorageKeys } from './storage.types';

type StorageObject = Record<string, unknown>;

@injectable()
export class LocalStorageService implements IStorageService {
  private values: BehaviorSubject<StorageObject | undefined> =
    new BehaviorSubject<StorageObject | undefined>(undefined);

  public store = <T>(key: KnownStorageKeys, value: T): void => {
    this.values.next(
      Object.assign({}, this.values.getValue(), { [key]: value }),
    );

    if (typeof window !== 'undefined') {
      window.localStorage.setItem(String(key), JSON.stringify(value));
    }
  };

  public retrieve = <T>(key: KnownStorageKeys): T | null => {
    if (typeof window !== 'undefined') {
      const value = window.localStorage.getItem(String(key));
      if (value) {
        return JSON.parse(value);
      }
    }

    return null;
  };

  public clean = (key: string): void => {
    const currentValues = Object.assign({}, this.values.getValue());
    delete currentValues[key];

    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(key);
    }

    this.values.next(currentValues);
  };

  private forceStorageClean = (): void => {
    const forceRefreshVersion = localStorage.getItem(
      KnownStorageKeys.ForceRefreshKey,
    );

    if (forceRefreshVersion !== KnownStorageKeys.ForceRefreshVersion) {
      localStorage.clear();
      localStorage.setItem(
        KnownStorageKeys.ForceRefreshKey,
        KnownStorageKeys.ForceRefreshVersion,
      );
    }
  };
}
