import { ExtendedRecipientInformation } from '@/pages/api/recipients/[id]';
import { RecipientEntity } from 'charlie-workflows/contracts';
import { Recipient } from 'charlie-workflows/types';
import { Observable } from 'rxjs';

export const RecipientsApiServiceContainerType = Symbol.for(
  'RecipientsApiService',
);

export interface IRecipientsApiService {
  getRecipients: () => Observable<ExtendedRecipientInformation[]>;
  getRecipientById: (
    recipientId: string,
  ) => Observable<ExtendedRecipientInformation>;
  save: (
    recipient: Recipient,
    sessionToAttach?: string,
  ) => Observable<RecipientEntity>;
  update: (
    recipientId: string,
    metadata: Partial<Recipient>,
  ) => Observable<RecipientEntity>;
  delete: (recipientId: string) => Observable<void>;
}
