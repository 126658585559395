import {
  AssistantMessage,
  Product,
  RecipientMetadata,
  SystemEvents,
  UserChatAction,
  UserChatActionMessage,
  UserMessage,
  UserProductCardAction,
  UserProductCardMessage,
} from 'charlie-workflows/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISseStreamParams } from '../chat-events';
import { SendMessageMetadata } from '../messages';
// import { IMessagesService } from '../messages';

export const ChatStorageServiceContainerType = Symbol.for('ChatStorageService');

export type IAnyMessage =
  | UserMessage
  | AssistantMessage
  | UserProductCardMessage
  | UserChatActionMessage;

export type ICombinedMessages = Record<string, IAnyMessage[]>;

export enum UserId {
  Charlie = 'charlie',
  User = 'user',
}

export interface IChatStorageService {
  // messages$: IMessagesService['messages$'];
  messages$: Observable<IAnyMessage[] | null>;
  systemEvents$: Observable<Array<SystemEvents> | null>;
  typingIndicator$: Observable<string | null>;
  isLoading$: Observable<boolean>;
  isChatInitialized$: Observable<boolean>;
  stream$: Observable<ISseStreamParams>;
  streamParams$: BehaviorSubject<ISseStreamParams>;

  getMessagesBySessionId(id: string): Observable<IAnyMessage[] | null>;
  sendMessage(chatId: string, details: SendMessageMetadata): void;
  sendProductMessage(
    type: UserProductCardAction,
    chatId: string,
    productId: string[],
    product?: Product,
  ): void;
  removeSystemEvent(event: SystemEvents): void;
  sendUserChatActionMessage(type: UserChatAction, chatId: string): void;
  initialize(chatId: string): void;
  destroy(): void;
}
