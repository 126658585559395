import { inject, injectable } from 'inversify';
import { IThumbsStatePayload } from '@/pages/api/products/feedback';
import { Observable, map } from 'rxjs';

import { IProductApiService } from './product-api.types';
import * as httpTypes from '../http';

@injectable()
export class ProductApiService implements IProductApiService {
  constructor(
    @inject(httpTypes.HttpServiceContainerType)
    private httpService: httpTypes.IHttpService,
  ) {}

  public getDislikedProducts = (sessionId: string): Observable<string[]> => {
    return this.httpService
      .makeRequest<{ dislikedProducts: string[] }>(
        `/api/products/disliked?sessionId=${sessionId}`,
        {
          method: 'GET',
        },
      )
      .pipe(map((response) => response.dislikedProducts));
  };

  public setThumbsState = ({
    state,
    productId,
    sessionId,
  }: IThumbsStatePayload): Observable<string> => {
    return this.httpService
      .makeRequest<{ id: string }>('/api/products/feedback', {
        method: 'POST',
        body: JSON.stringify({
          sessionId,
          state,
          productId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((response) => response.id));
  };
}
