import { ExtendedRecipientInformation } from '@/pages/api/recipients/[id]';
import { RecipientEntity } from 'charlie-workflows/contracts';
import { Recipient } from 'charlie-workflows/types';
import { Observable } from 'rxjs';

export const RecipientsServiceContainerType = Symbol.for('RecipientsService');

export interface IRecipientsService {
  recipients$: Observable<ExtendedRecipientInformation[] | null>;

  updateList: () => void;
  save: (
    recipient: Recipient,
    sessionToAttach?: string,
  ) => Observable<RecipientEntity>;
  update: (
    recipientId: string,
    metadata: Partial<Recipient>,
  ) => Observable<RecipientEntity>;
  delete: (recipientId: string) => Observable<void>;
}
