import {
  AssistantMessage,
  BusyMessage,
  RestoreMessages,
  ProceduralMessage,
  ChatMetadata,
} from 'charlie-workflows/types';
import { BehaviorSubject, Observable } from 'rxjs';

export const ChatEventsServiceContainerType = Symbol.for('ChatEventsService');

export type MessagesListener = (
  message:
    | AssistantMessage
    | ProceduralMessage
    | BusyMessage
    | RestoreMessages
    | ChatMetadata,
) => void;

export interface IChatEventsService {
  stream$: Observable<ISseStreamParams>;
  streamParams$: BehaviorSubject<ISseStreamParams>;
  close: () => void;
  subscribeToMessageEvents: (listener: MessagesListener) => void;
  unsubscribe: (listener: MessagesListener) => void;
}

export type PromptExperience = 'new-prompt-v1';

export interface ISseStreamParams {
  chatId?: string;
  topic?: string;
}
