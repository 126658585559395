import { ReactNode } from 'react';

interface IToasterContainerProps {
  children: ReactNode;
}

export const ToasterContainer: React.FC<IToasterContainerProps> = ({ children }: IToasterContainerProps) => {
  return <>
    {children}
  </>
}