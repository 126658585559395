import { Modal } from '@/src/shared/ui-kit/components/modal';
// import { motion } from 'framer-motion';
import { useInjectable, useSubscription } from '..';
import { IModalService, ModalServiceContainerType } from './modal.types';

// TODO: render from props
export const ModalContainer: React.FC<any> = () => {
  const { modals$, closeModal } = useInjectable<IModalService>(
    ModalServiceContainerType,
  );
  const modals = useSubscription(modals$);

  return (
    <>
      {modals?.map(
        ({
          component: Component,
          id,
          props: {
            onClose,
            className,
            bodyClassName,
            backdropClassName,
            contentClassName,
            ...props
          },
        }) => {
          return (
            <Modal.Container
              key={id}
              className={className}
              bodyClassName={bodyClassName}
              // contentClassName={contentClassName}
            >
              <Component
                onClose={() => {
                  closeModal(id);
                }}
                {...props}
              />
            </Modal.Container>
          );
        },
      )}
    </>
  );
};
