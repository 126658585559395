import { BehaviorSubject, Observable, forkJoin, from, map } from 'rxjs';
import Cookies from 'js-cookie';
import { injectable } from 'inversify';
import 'reflect-metadata';

import { IUserService, IUserData } from './user.types';
// import { uuid } from 'uuidv4';
// import { Gtag } from '../analytics/gtag.utils';

enum KnownCookies {
  Fingerprint = '_ga',
}

@injectable()
export class FingerprintUserService implements IUserService {
  private _userData$: BehaviorSubject<IUserData>;
  public userData$: Observable<IUserData>;

  constructor() {
    this._userData$ = new BehaviorSubject<IUserData>({
      fingerPrintId: Cookies.get(KnownCookies.Fingerprint),
    });

    this.userData$ = this._userData$.asObservable();
  }

  public setUserData = (data: Partial<IUserData>): void => {
    const currentValue = this._userData$.getValue();
    this._userData$.next({ ...currentValue, ...data });
  };
}
