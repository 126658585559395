import { injectable } from 'inversify';
import { FunctionComponent } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 } from 'uuid';
import {
  BaseModalProps,
  IModalService,
  Modal,
  ModalHandler,
} from './modal.types';
import 'reflect-metadata';

@injectable()
export class StackModalService implements IModalService {
  private _modals$: BehaviorSubject<Modal<any>[]> = new BehaviorSubject<
    Modal<any>[]
  >([]);
  public modals$: Observable<Modal<any>[]> = this._modals$.asObservable();

  public openModal = <T>(
    component: FunctionComponent<T>,
    props?: T & BaseModalProps,
  ): ModalHandler => {
    const id = v4();

    const onClose = () => {
      this.closeModal(id);
    };

    this._modals$.next(
      this._modals$
        .getValue()
        .slice()
        .concat([{ id, component, props: { ...(props || {}), onClose } }]),
    );

    return {
      id,
      onClose,
    };
  };

  public closeModal = (id: string): void => {
    const excludeModals = this._modals$
      .getValue()
      .slice()
      .filter((item) => item.id !== id);

    this._modals$.next(excludeModals);
  };
}
