import { Observable } from 'rxjs';
import { Product } from 'charlie-workflows/types';
import { IWishList } from 'charlie-workflows/workflows/users/wishlist';

export const WishlistsServiceContainerType = Symbol.for('WishlistsService');

export interface IWishlistsService {
  wishlists$: Observable<IWishList[] | null | null>;

  createNew: (
    wishlistName: string,
    recipientId?: string,
  ) => Observable<IWishList>;
  deleteWishlist: (wishlistId: string) => Observable<void>;
  retrieveOneById(wishlistId: string): void;
  retrieve: () => void;
  attachToWishlist: (
    wishlistId: string,
    product: Product,
  ) => Observable<IWishList>;
  saveItemForRecipient: (
    recipientId: string,
    product: Product,
  ) => Observable<IWishList>;
  removeFromWishlist: (
    wishlistId: string,
    productId: string,
  ) => Observable<IWishList>;
}
