import React, {
  Fragment,
  FunctionComponentElement,
  HTMLAttributes,
  ReactNode,
  TouchEvent,
  useEffect,
  useState,
} from 'react';
import styles from './modal.module.scss';

export interface ConfirmModalProps {
  onClose?: () => void;
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
  message?: ReactNode;
  subMessage?: ReactNode;
}

const Container: React.FC<{
  className?: string;
  bodyClassName?: string;
  backdropClassName?: string;
  children?: FunctionComponentElement<{ onClose: () => void }>;
}> = ({ children, className, bodyClassName, backdropClassName }) => {
  return (
    <Fragment>
      <div
        className={`${backdropClassName} ${styles.backdrop}`}
        onClick={children?.props.onClose}
      >
        <div className={`${className} ${styles.container}`}>
          <div className={`${bodyClassName} ${styles.body}`}>{children}</div>
        </div>
      </div>
    </Fragment>
  );
};

const Body: React.FC<
  {
    className?: string;
    children?: React.ReactNode;
  } & HTMLAttributes<HTMLDivElement>
> = ({ children, className, ...props }) => {
  return (
    <div className={`${className} ${styles.content}`} {...props}>
      {children}
    </div>
  );
};

export const Confirm: React.FC<ConfirmModalProps> = ({
  onClose,
  onConfirm,
  cancelText,
  confirmText,
  message,
  subMessage,
}) => {
  return (
    <div
      className={styles.confirmContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.confirmMessage}>{message}</div>
      <div className={styles.warning}>{subMessage}</div>
      <div className={styles.buttons}>
        <button className={styles.cancelBtn} onClick={onClose}>
          {cancelText}
        </button>
        <button
          type="submit"
          autoFocus
          className={styles.confirmBtn}
          onClick={onConfirm}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
};

export const Slide: React.FC<{
  children?: ReactNode;
  onClose?: () => void;
}> = ({ children, onClose }) => {
  const [touchYStartValue, setTouchYStartValue] = useState<null | number>(null);
  const [touchYMoveValue, setTouchYMoveValue] = useState<null | number>(null);
  const slideContainer = document.querySelector('.fullscreenContainerOverlay');
  const body = document.querySelector('body');

  useEffect(() => {
    body?.setAttribute('style', `overflow: hidden`);

    return () => {
      body?.removeAttribute('style');
    };
  }, [body]);

  useEffect(() => {
    if (touchYMoveValue && touchYMoveValue > 0) {
      slideContainer?.setAttribute('style', `bottom: -${touchYMoveValue}px`);
    }
  }, [touchYMoveValue, slideContainer]);

  const onTouchStart = (e: TouchEvent) => {
    setTouchYStartValue(e.touches?.[0].clientY);
  };

  const onTouchMove = (e: TouchEvent) => {
    e.stopPropagation();
    if (touchYStartValue) {
      setTouchYMoveValue(e.touches?.[0].clientY - touchYStartValue);
    }
  };

  const onTouchEnd = (e: TouchEvent) => {
    if (
      touchYStartValue !== null &&
      touchYStartValue < e.changedTouches?.[0].clientY
    ) {
      onClose?.();
    }
  };

  return (
    <div className={styles.slideContainer}>
      <div
        className={styles.controllerContainer}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
      >
        <div className={styles.controller}></div>
      </div>
      {children}
    </div>
  );
};

export const Modal = {
  Container,
  Body,
};
