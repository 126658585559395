import { inject, injectable } from 'inversify';
import { Recipient } from 'charlie-workflows/types';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import { RecipientEntity } from 'charlie-workflows/contracts';

import * as recipientsApiTypes from '../recipients-api';
import { IRecipientsService } from './recipients.types';
import { ExtendedRecipientInformation } from '@/pages/api/recipients/[id]';

@injectable()
export class RecipientsService implements IRecipientsService {
  private _recipients$: BehaviorSubject<ExtendedRecipientInformation[] | null> =
    new BehaviorSubject<ExtendedRecipientInformation[] | null>(null);
  public recipients$: Observable<ExtendedRecipientInformation[] | null> =
    this._recipients$.asObservable();

  constructor(
    @inject(recipientsApiTypes.RecipientsApiServiceContainerType)
    private recipientsApiService: recipientsApiTypes.IRecipientsApiService,
  ) {}

  public updateList: () => void = () => {
    this.recipientsApiService
      .getRecipients()
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe((data) => {
        this._recipients$.next(data);
      });
  };

  public save: (
    recipient: Recipient,
    sessionToAttach?: string,
  ) => Observable<RecipientEntity> = (recipient, sessionToAttach) => {
    return this.recipientsApiService
      .save(recipient, sessionToAttach)
      .pipe(tap(this.updateList));
  };

  public update: (
    recipientId: string,
    metadata: Partial<Recipient>,
  ) => Observable<RecipientEntity> = (recipientId, metadata) => {
    return this.recipientsApiService
      .update(recipientId, metadata)
      .pipe(tap(this.updateList));
  };

  public delete: (recipientId: string) => Observable<void> = (recipientId) => {
    return this.recipientsApiService
      .delete(recipientId)
      .pipe(tap(this.updateList));
  };
}
